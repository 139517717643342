.carouselSection {
  width: 100%;
  overflow: hidden;
  margin: 32px 0 0 0;
  box-sizing: border-box;
}
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
  max-width: 792px;
  overflow: hidden;
  margin: 0 auto;
  box-sizing: border-box;
}
.header {
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
.header > h2 {
  display: flex;
  align-items: center;
  flex: 1;
  color: var(--Neutrals-White, #FFF);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Libre Franklin";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 16.1px */
  letter-spacing: 2px;
  text-transform: uppercase;
}
.header > a {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--Gray---96, #F5F5F5);
  font-family: "Libre Franklin";
  font-size: 12px;
  font-weight: 600;
  line-height: 110%; /* 13.2px */
  letter-spacing: 0.48px;
  text-transform: uppercase;
  text-decoration: none;
}
.carousel {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  overflow: scroll;
  gap: 16px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
}
.carousel::-webkit-scrollbar {
  display: none;
}
.carouselItem {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  scroll-snap-align: start;
  cursor: pointer;
  width: 227px;
}
.carouselThumbailContainer {
  position: relative;
  width: 100%;
  border-radius: 4px;
  height: 128px;
}

.carouselThumbailContainer > img {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 4px;
}
.carouselItemText {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.carouselItemText > h3 {
  color: var(--Neutrals-White);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-weight: 600;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-top: 24px;
}
.carouselItemText > p {
  color: var(--neutrals-40);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: "Libre Franklin";
  font-size: 14px;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  margin-top: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: rgba(0,0,0,.9);
}
.scrollButton {
  display: none;
  position: absolute;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
  border: none;
  background-color: rgba(0,0,0,.9);
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: opacity 0.3s ease-in;
  cursor: pointer;
}
.scrollButton.left {
  left: 12px;
}
.scrollButton.left > svg {
  transform: rotate(180deg);
}
.scrollButton.right {
  right: 12px;
}
.invisible {
  opacity: 0;
}
@media (min-width: 1000px) {
  .wrapper {
    gap: 48px;
    max-width: 1400px;
  }
  .header > h2 {
    font-size: 16px;
  }
  .header > a {
    font-size: 16px;
    letter-spacing: 0.64px;
  }
  .carouselItem {
    width: 338px;
  }
  .carouselThumbailContainer {
    height: 190px;
  }
  .carouselItemText > h3 {
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: unset;
    -webkit-line-clamp: 1;
  }
  .playButton {
    transform: unset;
    top: unset;
    left: 8px;
    bottom: 8px;
  }
  .scrollButton {
    display: flex;
  }
}