.paywallBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  background: rgba(31, 31, 31, 0.20);
  z-index: 2;
}

.paywallBoxElection {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  background: rgba(228, 228, 228,0.2);
  z-index: 2;
}
.paywallBoxElection > p {
  color: #172E39;
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  line-height: 150%; /* 25.5px */
  letter-spacing: 0.34px;
  max-width: 240px;
  margin-bottom: 8px;
}

.paywallBoxElection > button {
  border: none;
  height: 56px;
  padding: 0px 24px;
  border-radius: 64px;
  background: var(--Button-Primary-Active-Surface, black);
  color: #FFF;
  font-size: 15px;
  font-weight: 700;
  line-height: 110%; /* 16.5px */
  letter-spacing: -0.3px;
}

.paywallBox > p {
  color: var(--Text-Default-Base, #FFF);
  text-align: center;
  font-size: 17px;
  font-weight: 400;
  line-height: 150%; /* 25.5px */
  letter-spacing: 0.34px;
  max-width: 240px;
  margin-bottom: 8px;
}

.paywallBox > button {
  border: none;
  height: 56px;
  padding: 0px 24px;
  border-radius: 64px;
  background: var(--Button-Primary-Active-Surface, #FFF);
  color: var(--Decorative-Almost-Black, #151515);
  font-size: 15px;
  font-weight: 700;
  line-height: 110%; /* 16.5px */
  letter-spacing: -0.3px;
}

/* Nest Episode Overlay Styles */

.nextEpisodeOverlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
.nextEpisodeOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 1);
  animation: fadeDown 200ms linear forwards;
}
.nextEpImageContainer {
  width: 100%;
  max-width: 300px;
  border-radius: 8px;
  margin-bottom: 16px;
  z-index: 1;
  opacity: 0;
  animation: fadeUp 400ms 400ms ease-in-out forwards;
  position: relative;
}
.nextEpImage {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.nextEpDuration {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  bottom: 12px;
  right: 12px;
  z-index: 1;
  padding: 4px;
  border-radius: 2px;
  background-color: rgba(10,9,9,0.9);
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}
.nextEpisodePaywallMessage, .heading > p:nth-child(1) {
  color: var(--Text-Default-Strong, #8F8F8F);
  text-align: center;
  /* Subtitle 2 */
  font-family: "Libre Franklin";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.24px;
  z-index: 1;
  margin-bottom: 8px;
  opacity: 0;
  animation: fadeUp 400ms 500ms ease-in-out forwards;
}
.heading {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heading > p:nth-child(1) {
  margin-bottom: 16px;
  animation: fadeUp 400ms 400ms ease-in-out forwards;
}

.heading > h3 {
  color: #FFF;
  text-align: center;
  /* Article Header */
  font-family: "Libre Franklin";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 34.8px */
  z-index: 1;
  margin-bottom: 24px;
  max-width: 400px;
  opacity: 0;
  animation: fadeUp 400ms 500ms ease-in-out forwards;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.buttonContainer {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.continueWatchingButton {
  display: flex;
  height: 56px;
  padding: 0px 24px;
  border-radius: 64px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  background: var(--Decorative-White, #FFF);
  color: var(--Decorative-Almost-Black, #151515);
  border: none;
  z-index: 1;
  font-size: 15px;
  font-weight: 700;
  line-height: 110%; /* 16.5px */
  letter-spacing: -0.3px;
  opacity: 0;
  animation: fadeUp 400ms 600ms ease-in-out forwards;
}
.cancelButton {
  z-index: 1;
  color: #FFF;
  font-size: 17px;
  font-weight: 400;
  line-height: 150%; /* 25.5px */
  letter-spacing: 0.34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-top: 16px;
  opacity: 0;
  animation: fadeUp 400ms 700ms ease-in-out forwards;
}
.cancelButton > svg {
  display: none;
}
@media screen and (max-width: 1000px) {
  .nextEpImageContainer {
    max-width: 250px;
  }
  .heading > h3 {
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
  .heading > p:nth-child(1) {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .nextEpisodeOverlay {
    position: fixed;
    z-index: 1000;
    padding: 22px;;
  }
  .nextEpImageContainer {
    width: 217px;
    margin-bottom: 32px;
  }
  .nextEpImage {
    width: 100%;
  }
  .nextEpisodeOverlay::after {
    animation: unset;
  }
  .cancelButton > svg {
    display: unset;
  }
  .cancelButton > span {
    display: none;
  }
  .heading.hasAccess {
    flex-direction: row;
    gap: 16px;
    align-items: unset;
  }
  .nextEpisodePaywallMessage {
    font-size: 14px;
  }
  .heading > h3 {
    font-size: 24px;
    line-height: 130%;
    margin-bottom: 64px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
  .cancelButton {
    margin-top: 32px;
  }
  .nextEpisodeOverlay.hasAccess {
    position: absolute;
    z-index: 99;
    align-items: flex-start;
  }
  .nextEpImageContainer.hasAccess {
    width: 100%;
    max-width: 300px;
    min-width: 151px;
    margin-bottom: unset;
    border-radius: 4px;
  }
  .nextEpImage.hasAccess {
    width: 100%;
    border-radius: 4px;
  }
  .nextEpisodeOverlay.hasAccess::after {
    animation: fadeDown 200ms linear forwards;
  }
  .cancelButton.hasAccess > svg {
    display: none;
  }
  .cancelButton.hasAccess > span {
    display: unset;
  }
  .heading.hasAccess > h3 {
    font-size: clamp(14px, 3vw, 24px);
    line-height: 130%;
    margin-bottom: unset;
    align-self: center;
    text-align: start;
    animation: fadeUp 400ms 400ms ease-in-out forwards;
  }
  .buttonContainer.hasAccess {
    flex-direction: row;
    align-items: center;
    gap: 32px;
    margin-top: 48px;
  }
  .continueWatchingButton.hasAccess {
    width: 125px;
    height: 36px;
    font-size: 12px;
    line-height: 110%; /* 13.2px */
    letter-spacing: -0.24px;
    padding: 0;
    animation: fadeUp 400ms 400ms ease-in-out forwards;
  }
  .continueWatchingButton.hasAccess > svg {
    width: 16px;
    height: 16px;
  }
  .cancelButton.hasAccess {
    margin-top: 0;
    order: -1;
    font-size: 14px;
    font-weight: 600;
    animation: fadeUp 400ms 400ms ease-in-out forwards;
  }
  .nextEpDuration {
    right: 8px;
    bottom: 8px;
  }
}

@keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeDown {
  from {
    background: rgba(0, 0, 0, 1);
  } 
  to {
    background: rgba(0, 0, 0, 0.9);
  }
}