.isReply {
  border-bottom: 0px none !important;
}

.liveChatSection {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
}

.liveChatSectionElection {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 1024px) {
  .liveChatSectionElection {
    height: 100%;
  }
}

.liveChatMainWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  padding: 0 24px;
}

.liveChatMain {
  position: relative;
  flex-grow: 1;
  min-height: 200px;
  overflow: auto;
  mask-image: linear-gradient(
    to bottom,
    transparent,
    #000 24px,
    #000 calc(100% - 32px),
    transparent
  );

  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.liveChatMain::-webkit-scrollbar {
  display: none;
}

/*@media (min-width: ${breakpoints.desktop}px) {
  min-height: 560px;
} */

.liveChatMessages {
  display: flex;
  position: absolute;
  flex-direction: column-reverse;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.message {
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
}

.messageAvatar {
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  margin-right: 8px;
  overflow: hidden;
  border-radius: 50%;
  background-color: rgb(38, 38, 38);
  color: rgb(153, 153, 153);
  font-size: 11px;
  font-weight: 900;
  line-height: 28px;
  text-align: center;
}

.staffName {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  color: rgb(230, 230, 230);
  font-size: 14px;
  font-weight: 700;
  line-height: 1.25;
}

.staffNameElection {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  color: #8F8F8F;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.25;
}

.messageText {
  color: var(--Text-Default-Base, #FFF);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-weight: 400;
  line-height: 180%; /* 21px */
  letter-spacing: 0.28px;
  word-break: break-word;
  white-space: pre-line;
}
.messageTextOverride {
  color: var(--Text-Default-Base, #000000);
  font-family: "Libre Franklin";
  font-size: 14px;
  font-weight: 400;
  line-height: 180%; /* 21px */
  letter-spacing: 0.28px;
  word-break: break-word;
  white-space: pre-line;
}
.messageText > span {
  color: var(--Text-Inverse-Disabled, #8F8F8F);
  font-size: 14px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  margin-right: 8px;
}

.messageTextAdmin > span {
  color: var(--Text-Inverse-Disabled, #DB3D3B);
  font-size: 14px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  margin-right: 8px;
}
.messageTextAdminOverride > span {
  color: var(--Text-Inverse-Disabled, #DB3D3B);
  font-size: 14px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  margin-right: 8px;
}

.messageTextElection {
  color: #000;
  font-family: "Libre Franklin";
  font-size: 14px;
  font-weight: 400;
  line-height: 180%; /* 21px */
  letter-spacing: 0.28px;
  word-break: break-word;
  white-space: pre-line;
}

.messageTextElection > span {
  color: var(--Text-Inverse-Disabled, #8F8F8F);
  font-size: 14px;
  font-weight: 600;
  line-height: 120%; /* 16.8px */
  margin-right: 8px;
}

.liveChatMessageMessage {
  display: flex;
  color: #fff;
}

.replyList {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 6px;
  padding-left: 24px;
  list-style: none;
}

.openReplyListButton {
  display: flex;
  align-items: center;
  /* These margins/paddings are to extend the tap/click area */
  margin-top: -8px;
  margin-bottom: -8px;
  padding: 0;
  border: 0px none;
  background-color: transparent;
  color: var(--Text-Default-Strong, #8F8F8F);
  font-size: 12px;
  -moz-box-align: center;
  appearance: none;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.openReplyListButton strong {
  margin-right: 8px;
  font-weight: 700;
}

.messageRootElection {
 padding: 24px 0 16px;
}

.messageRootElection:first-of-type {
  padding-bottom: 30px;
}

.messageRoot {
  padding: 12px 0;
}

@media (max-width: 950px) {
  .messageRootElection {
    padding: 5px 0 10px;
  }
  .messageRoot {
    padding: 8px 0;
  }
}

.messageRootAdmin {
  padding: 24px 16px 16px;
  background-color: #282828;
  border-radius: 8px;
  margin: 2px 0;
}
.messageRootAdminOverride {
  padding: 24px 16px 16px;
  background-color: #575656;
  border-radius: 8px;
  margin: 2px 0;
}
.messageRootAdmin:first-of-type {
  padding-bottom: 30px;
}
.messageRootAdminOverride:first-of-type {
  padding-bottom: 30px;
}
/* helps first message clear the gradient */
.messageRoot:first-of-type {
  padding-bottom: 32px;
}
.messageFormRoot {
  position: relative;
}
.messageFormRoot > textarea {
  font-family: "Libre Franklin";
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 24px 48px 24px 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;
}
.messageFormRootElection > textarea {
  font-family: "Libre Franklin";
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 15px 48px 15px 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.28px;
  background-color: gainsboro;
  color: #000;
}
.messageFormRootElection {
  position: relative;
}
.messageFormRootElection > textarea::placeholder {
  color: #000;
}
.messageFormRoot > textarea::placeholder {
  font-family: "Libre Franklin";
  color: var(--Text-Default-Strong, #8F8F8F);
}
.commentTextArea {
  display: block;
  width: 100%;
  min-width: 100%;
  max-width: 450px;
  padding: 16px 72px 16px 24px;
  overflow-y: auto;
  border: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.03);
  color: #fff;
  font-size: 16px;
  resize: none;
}

.commentTextArea:focus {
  outline: 0;
}

.commentTextAreaElection {
  display: block;
  width: 100%;
  min-width: 100%;
  max-width: 450px;
  padding: 16px 72px 16px 24px;
  overflow-y: auto;
  border: 0;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.03);
  color: #000;
  font-size: 16px;
  resize: none;
}

@media (max-width: 767px) {
  .commentTextAreaElection {
    border-radius: 0px;
  }
}

.commentButton {
  display: flex;
  position: absolute;
  top: 16px;
  right: 8px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  transition: opacity 0.2s;
  border: 0;
  background-color: transparent;
  appearance: none;
}
.commentButtonElection {
  display: flex;
  position: absolute;
  top: 16px;
  right: 8px;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  transition: opacity 0.2s;
  border: 0;
  background-color: transparent;
  appearance: none;
}
.commentButton:disabled {
  opacity: 0.5;
}

.replyFormRoot {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.02);
}

.replyFormHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.replyFormHeaderElection {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: lightgray;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.replyFormIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 6px 14px 20px;
  color: #999;
}

.replyFormIconElection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 6px 14px 20px;
  color: #000;
}

.replyFormReSuffix {
  margin-right: 8px;
  padding: 12px 0;
  color: #999;
  line-height: 1.5;
}

.replyFormReSuffixElection {
  margin-right: 8px;
  padding: 12px 0;
  color: #000;
  line-height: 1.5;
}

.replyFormOriginalMessage {
  flex: 1;
  max-height: 90px;
  padding: 12px 12px 12px 0;
  overflow: hidden;
  color: #ccc;
  line-height: 1.5;
  mask-image: linear-gradient(to top, transparent 0, black 20px);
}

.replyFormOriginalMessageElection {
  flex: 1;
  max-height: 90px;
  padding: 12px 12px 12px 0;
  overflow: hidden;
  color: #000;
  line-height: 1.5;
  mask-image: linear-gradient(to top, transparent 0, black 20px);
}
.replyFormCloseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 12px;
  transition: all 0.2s;
  border: none;
  background: transparent;
  color: #bbb;
}

.replyFormCloseButton:hover {
  color: white;
}

.replyFormCloseButtonElection {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 12px;
  transition: all 0.2s;
  border: none;
  background: transparent;
  color: #000;
}
